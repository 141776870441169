@import "variables";

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-background-color;
  background-image: url("../assets/images/home-bg-xs.jpg");
  background-repeat: repeat;
  background-position: top left;
  background-size: cover;
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0 0 10px 0;
}

a:link, a:visited {
  color: $link-color;
  text-decoration: none;
}

.primary {
  color: $primary-color;
}

.secondary {
  color: $secondary-color;
}

.center-text {
  text-align: center;
}

.p1 {
  padding: 1em;
}
.p2 {
  padding: 2em;
}

.app-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $app-bar-height;
  overflow: hidden;
  white-space: nowrap;
}

.app-bar a:link, .app-bar a:visited {
  color: white;
}

.screen-with-app-bar {
  position: fixed;
  top: calc(#{$app-bar-height} + 10px);
  left: 0;
  right: 0;
  bottom: calc(#{$copyright-height} + 10px);
  overflow: auto;
}

.screen-centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sidenav img {
  display: block;
  max-width: 80%;
  max-height: 60px;
  width: auto;
  height: auto;
  margin: 15px auto;
}

.sidenav h3 {
  margin: 1em 0 0.8em 0.8em;
  padding: 0;
  color: $lighter-text-color;
  font: 0.75em sans-serif;
  text-transform: uppercase;
}

.sidenav .MuiDrawer-paper {
  width: 400px;
  max-width: 80%;
}

.sidenav .MuiListItem-root {
  border-bottom: 1px solid $border-color;
}

.sidenav .MuiListItemIcon-root {
  min-width: 36px;
}

.sidenav a:link, .sidenav a:visited {
  color: $text-color;
}

.copyright-bar {
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: $copyright-height;
  z-index: 999;
  background-color: $dark-background-color;
  display: flex;
  flex-direction: row;
  font: 0.65em sans-serif;
  color: $light-text-color;
  padding: 0 0.6em;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 6px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.copyright-bar a:link, .copyright-bar a:visited {
  color: $text-color;
}

.flex-grow-1 {
  flex-grow: 1;
}

.buttons-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  button {
    margin: 10px;
  }
}

hr.sep {
  width: 90%;
  border: 0;
  border-bottom: 1px dotted $border-color;
}
