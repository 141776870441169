@import "variables";

.app-snack-bar.MuiSnackbarContent-root {
  background-color: #882322;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0 8px;

  #client-snackbar {
    display: flex;
    align-items: center;

    .icon {
      font-size: 20px;
      opacity: 0.9;
      margin-right: 3px;
    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 65vw;
    }
  }
}

.menu-button {
  margin-right: 5px;
  color: white;
}

.menu-icon {
  margin-right: 10px;
}

.logo {
  display: block;
  max-width: 80%;
  max-height: 40px;
  width: auto;
  height: auto;
}
