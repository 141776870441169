$primary-color: #8500ff;
$secondary-color: #fa9600;

$text-color: #333333;
$light-text-color: #888888;
$lighter-text-color: #aaaaaa;
$link-color: #0d47a1;

$light-background-color: #fafafa;
$dark-background-color: #dddddd;

$border-color: #dddddd;

$app-bar-height: 65px;
$copyright-height: 25px;

$highlight-color: #da1627;
